@font-face {
	font-family: 'Achemine';
	src: url('../fonts/Achemine.ttf') format('truetype');
	/* Legacy iOS */
}

@font-face {
	font-family: 'Transilien';
	src: url('../fonts/TransilienSmall.ttf') format('truetype');
	/* Legacy iOS */
}

.slug1 {
	border: 4px solid #eb2132;
}

.slug2 {
	border: 4px solid #f58f53;
}

.slug0 {
	border: 4px solid #ffffff00;
}

/* --- */
.TALOS {
	background-color: #fff;
	color: var(--text);
	font-family: Luciole, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	margin: 0;
	overflow: hidden;
	width: 100vw;
	height: 100vh;
	animation: 0.8s ease 0s 1 normal none running Opacity;
}

.TALOS .departure {
	background: linear-gradient(240deg, #dfc1c1, #dcdeee);
	height: 100vh;
}

.TALOS .arrival {
	background: linear-gradient(240deg, #c1cedf, #dceedf);
	height: 100vh;
}

.TALOS .head {
	height: 9vh;
}

.TALOS .head .title {
	color: #4f4f4f;
	left: 11.5vw;
	position: fixed;
	font-weight: 700;
	font-size: 5vh;
	top: 0.8vh;
}

.TALOS .time {
	font-family: 'Transilien';
	color: #f6cd2f;
	background-color: #141414;
	padding: 15px;
	border-radius: 10px;
	position: relative;
	height: calc(100% - 33px);
	width: 9vw;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 0 0 2vh;
}

.TALOS .time div {
	width: 100%;
}

.TALOS .hour {
	font-family: 'Transilien';
	font-size: 7vh;
	color: #f6cd2f;
	background-color: #141414;
	padding: 5px 5px 5px 20px;
	border-radius: 10px;
	position: absolute;
	width: 9vw;
	display: inline-block;
	text-align: center;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, .2), 0 5px 5px 0 rgba(0, 0, 0, .24);
	top: -7px;
	left: -15px;
}

.TALOS .départ {
	margin: 1vw 2vw 0vw 1.1vw;
	background-color: #00000014;
	border-radius: 10px;
	height: 8.8vh;
	position: relative;
	z-index: 3;
	overflow: hidden;

	display: flex;
	align-items: center;
	padding: 0.5vh;
}

.TALOS .départ .img {
	height: 6vh;
	width: 15vh;
	text-align: center;
	padding: 1vh 1vh 1vh 2vh;
}

.TALOS .départ img {
	max-width: 100%;
	max-height: 100%;
}

.TALOS .miss {
	width: 12vw;
	text-align: center;

	color: #4f4f4f;
	font-size: 5vh;
	font-weight: 700;
}

.TALOS .dest {
	color: #4f4f4f;
	display: inline-block;
	font-size: 6vh;
	font-weight: 700;
	margin-left: 2vw;
	position: relative;
	width: 8vw;
}

/* --- */

.TALOS .deleted {
	border: 4px solid #eb2132;
}

.TALOS .late {
	border: 4px solid #f58f53;
}

.TALOS .ok {
	border: 4px solid #ffffff00;
}

.TALOS .interrogation {
	border: 4px solid #ffffff00;
}

/* --- */

.TALOS .trafic {
	max-height: 5.5vh;
	position: absolute;
	margin-top: -4vh;
	margin-left: 96vw;
	z-index: 5;
}

.TALOS .message {
	font-size: 3vh;
	background-color: #f58f53;
	color: #fff;
	padding: 5px;
	border-radius: 10px 10px 10px 0px;
	position: relative;
	left: -10px;
	top: 2px;
	z-index: -10;
}

.TALOS .message_info {
	font-size: 3vh;
	background-color: #005bbc;
	color: #fff;
	padding: 5px;
	border-radius: 10px 10px 10px 0px;
	position: relative;
	left: -10px;
	top: 2px;
	z-index: -10;
}

.TALOS .message_delete {
	font-size: 3vh;
	background-color: #eb2031;
	color: #fff;
	padding: 5px;
	border-radius: 10px 10px 10px 0px;
	position: relative;
	left: -10px;
	top: 2px;
	z-index: -10;
}

.TALOS .trafic_img {
	max-height: 5.5vh;
	position: absolute;
	margin-top: -4vh;
	margin-left: 94vw;
	z-index: 5;
}