body {
	color: #fff;
	margin: 0;
	overflow: hidden;
	white-space: nowrap;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
code {
  	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.example {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	z-index: 10;
	opacity: 0.5;
}

.gui-menu h3 {
	margin: 17px;
}
.settings-full{
	margin-top: 70px;
}

.hr{
	background-color: #868686;
	border-radius: 2px;
	height: 2px;
	margin: 0 10px;
}

.gui{
	background-color: #202020;
	color: #fff;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	position: fixed;
	z-index: 11;

	width: 400px;
	max-width: calc(100vw - 40px);

	height: 100vh;
	top: 0;
	left: 0;
	padding: 20px;
	animation: gui 1s ease;	
}

.gui-back{
	background-color: #1f1f1fe0;
	position: fixed;
	z-index: 9;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	animation: gui-back 1s ease;	
}

.gui-menu{
	padding: 20px;
	position: fixed;
	font-size: 20px;
	color: #7c7c7c;
	animation: gui-menu 1s ease;
}
.gui-menu input{
    margin: 0;
	padding: 0;
    display: none;
}

.SNCFd-card{
	background-image:url("../../assets/img/SNCFd.png");
}
.SNCFa-card{
	background-image:url("../../assets/img/SNCFa.png");
}
.IENAd-card{
	background-image:url("../../assets/img/IENAd.png");
}
.IENAa-card{
	background-image:url("../../assets/img/IENAa.png");
}
.TALOSd-card{
	background-image:url("../../assets/img/TALOSd.png");
}
.TALOSa-card{
	background-image:url("../../assets/img/TALOSa.png");
}


.gui-menu input:active +.selectcard-cc{
	opacity: 0.9;
}
.gui-menu input:checked +.selectcard-cc{
    filter: none;
}
.selectcard-cc{
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    width: 16vw;
	height: 9vw;
	margin-left: 20px;
	border-radius: 5px;
	transition: all 250ms ease;
    filter: brightness(1.2) grayscale(1) opacity(1);
}
.selectcard-cc:hover{
    filter: brightness(1.2) grayscale(.5) opacity(1);
}
.center{
	color: #7c7c7c;
}
.overmouse, .overmouse2{
	color: #fff;
	text-decoration: none;
	position: initial;
	padding: 10px;
	display: block;
	border-radius: 5px;
	transition: background-color 250ms;
	cursor: pointer;
}
.overmouse{
	background-color: #fff0;
}
.overmouse2{
	background-color: #0063aa40;
}
.overmouse:hover{
	background-color: #0064aab7;
}
.overmouse2:hover{
	background-color: #0064aab7;
}
.close{
	text-align: end;
	cursor: pointer;
}

@media only screen and (min-width: 1000px) {
	.stopList{
		overflow-x: hidden;
		overflow-y: scroll;
		height: calc(100vh - 310px);
	}
}
@media only screen and (max-width: 999px) {
	.stopList{
		overflow-x: hidden;
		overflow-y: scroll;
		height: calc(100vh - 444px);
	}
}
.gui .about{
	bottom: 0px;
	position: fixed;
}
.logo{
	max-width: 100px;
}
.progress-bck {
	background-color: var(--acrylic-light);
	border-radius: 5px;
	float: left;
	height: 1vmin;
	position: relative;
	width: 100%
}

.progress {
	background-color: var(--blue-primary);
	border-radius: 5px;
	float: left;
	height: 1vmin;
	position: relative;
	top: -1vmin;
	transition: all .5s;
	width: 0
}
.indeterminate{
	animation: indeterminate 1.3s ease infinite;
}

.hover{
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 4;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

/* --- PC --- */
@media only screen and (min-width: 1000px) {
	.gui-menu{
		top: 0;
		left: 440px;
		z-index: 10;
		max-width: calc(100vw - 440px);
		animation: gui-menu1 1s ease;
	}
	.gui-menu .hr{
		display: none;
	}
}
/* --- Mobile --- */
@media only screen and (max-width: 999px) {
	.gui-menu{
		bottom: 0;
		left: 0;
		z-index: 11;
		max-width: 380px;
		max-height: 315px;
		overflow-y: scroll;
		overflow-x: hidden;
		animation: gui-menu2 1s ease;
	}
	.gui-menu .selectcard-cc{
		width: 170px;
		max-width: 40vw;
		height: 95px;
	}
	.gui .hr{
		margin: 17px;
	}
	.about{
		display: none;
	}
}

/* --- GUI --- */

.gui-show{
	width: 400px;
	max-width: 100vw;
	position: fixed;
	height: 100vh;
	top: 0;
	left: 0;
	animation: gui-show 5s linear;
	z-index: 5;
}
.gui-show span{
	position: fixed;
	text-shadow: 3px 2px 4px #0b0b0b;
	writing-mode: sideways-lr;
	text-orientation: mixed;
	top: calc(( 100vh - 410px ) / 2 );
	left: 30px;
	font-size: 3vh;
	animation: gui-span 5s linear;
	opacity: 0;
	z-index: 5;
}
.gui-mobile{
	width: 100vw;
	height: 50px;
	top: 0;
	left: 0;
	position: fixed;
	background-color: #262626;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
	z-index: 5;
	text-align: center;
}
.gui-mobile img{
	width: 30px;
	height: 30px;
	top: 9px;
	left: 15px;
	float: left;
	position: relative;
	cursor: pointer;
	transition: all 250ms ease;
	filter: invert(100%) sepia(93%) saturate(27%) hue-rotate(39deg) brightness(107%) contrast(107%);
}
.gui-mobile img:hover{
	width: 24px;
	height: 30px;
	padding: 0 3px;
}
.gui-mobile span{
	top: 9px;
	padding-right: 65px;
	position: relative;
	font-size: 20px;
	font-weight: lighter;
}

/* --- Animation --- */

@keyframes indeterminate {
	0%{
		width: 0px;
		left: 0%;
	}
	50%{
		width: 100%;
		left: 0%;
	}
	100%{
		width: 0px;
		left: 100%;
	}
}
@keyframes gui-show {
	0% {
		background: linear-gradient(to right, #ffffff99, #0000);
		opacity: 1;
	}
	80% {
		background: linear-gradient(to right, #ffffff99, #0000);
		opacity: 1;
	}
	100% {
		background: linear-gradient(to right, #ffffff99, #0000);
		opacity: 0;
	}
}
@keyframes gui-span {
	0% {
		opacity: 1;
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@keyframes gui {
	0% {
		left: -440px;
	}
	100% {
		left: 0;
	}
}
@keyframes gui-menu1 {
	0% {
		opacity: 0;
		left: -0px;
	}
	100% {
		opacity: 1;
		left: 440px;
	}
}
@keyframes gui-menu2 {
	0% {
		opacity: 0;
		left: -400px;
	}
	100% {
		opacity: 1;
		left: 0px;
	}
}
@keyframes gui-back {
	0% {
		background-color: #1f1f1f00;
	}
	100% {
		background-color: #1f1f1fe0;
	}
}


body{
	background-color: var(--body);
}
.error{
	width: 100vw;
	height: 100vh;
	background: black;
	color: #fff;
	top: 0;
	left: 0;
	position: absolute;
}
.error2{
	left: 8%;
	top: 120px;
	position: relative;
	font-size: 22px;
	width: calc(100vw - 16%);
}
.settings-scroll, .settings-options{
	white-space: normal;
}
.home{
	position: fixed;
	bottom: 10px;
	padding: 50px 50px 0 50px;
	right: 0;
}
.home span{
	color: #868686;
}
.home .logo{
	height: 50px;
}
.home a{
	color: #297CD3;
	margin-bottom: 5px;
}

.options{
	animation: 0.8s ease 0s 1 normal none running SettingsOptions;
}

.small_fluent_btn2{
	height: 39px;
}

iframe{
	border: 0;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	width: 35vw;
	height: 19.6vw;
	border-radius: 5px;
}
.about table{
	border-radius: 5px;
	background-color: #003360;
	color: #fff;
}
.about th{
	min-width: 130px;
}
.about tr{
	text-align: center;
}
.about td, .about th{
	padding: 10px;
}
.detail{
	text-align: left;
}
.about tbody{
	background-color: #003360;
}
.about .table2{
	background-color: #004786;
}

.column {
	display:inline-block;
	width:300px;
}

.space {
	display: block;
	margin: 0;
	border-bottom: 2px solid #868686;
}
  
@keyframes SettingsOptions {
	0% {
		margin-left:50px;
		margin-right:-50px;
		opacity:0;
	}
	100% {
		margin-left:0;
		opacity:1;
	}
}
@keyframes Opacity {
	0% {
		opacity:0;
	}
	100% {
		opacity:1;
	}
}