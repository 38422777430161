@font-face {
	font-family: 'Achemine';
	src: url('../fonts/Achemine.ttf') format('truetype');
	/* Legacy iOS */
}

.SNCF img {
	max-height: 5.5vh;
	margin-left: 1.2vw;
	max-width: 7vw;
}

.SNCF table,
.SNCF tr {
	border-spacing: 0;
	width: 100vw;
}

.SNCF {
	font-family: "Achemine", Arial, sans-serif;
}

.SNCF .departure {
	background-color: #003a79;
	width: 100vw;
	height: 100vh;
}

.SNCF .arrival {
	background-color: #1f5628;
	width: 100vw;
	height: 100vh;
}

.SNCF .img {
	width: 9.4vw;
	padding-right: 1vw;
	min-width: 8.7vw;
}

.SNCF .trafic {
	width: 12vw;
}

.SNCF .max {
	height: 8.9vh;
}

.SNCF .min {
	height: 9.9vh;
}

.SNCF .departure .départ1 {
	background-color: #0064AA;
}

.SNCF .departure .départ2 {
	background-color: #003A79;
}

.SNCF .departure .arr {
	color: #4B84CA;
}

.SNCF .arrival .départ1 {
	background-color: #187936;
}

.SNCF .arrival .départ2 {
	background-color: #1F5628;
}

.SNCF .arrival .arr {
	color: #458C4D;
}

/* -------------------------------------- */
.SNCF .info{
	font-weight: bold;
}
.SNCF .info,
.SNCF .Id {
	display: inline-block;
}

/* -------------------------------------- */

.SNCF .late {
	color: #fcfd02;
	font-weight: bold;
}

.SNCF .deleted {
	color: #ffa500;
	font-weight: bold;
}

.SNCF .dot {
	position: relative;
	border-radius: 50%;
	background: yellow;
	margin: 1vh;
	top: 1.2vh;
	width: 2.3vh;
	height: 2.3vh;
	display: inline-block;
}

.SNCF .trafic {
	font-size: 3.8vh;
	overflow: hidden;
	max-width: 12vw;
	min-width: 12vw;
}

.SNCF .time {
	font-size: 7.3vh;
	font-weight: bold;
	color: yellow;
	padding-right: 1vw;
	width: 10vw;
}

.SNCF .max .time {
	font-size: 7.4vh;
}

.SNCF .min .time {
	font-size: 6.6vh;
}

.SNCF .arr {
	transform: rotate(270deg);
	position: absolute;
	font-weight: bold;
	right: -25vh;
	font-size: 24vh;
	top: 32vh;
	z-index: 1;
}

.SNCF .dest,
.SNCF .stop,
.SNCF .time {
	z-index: 2;
	position: relative;
	max-width: 92vw;
}

.SNCF .stop {
	font-size: 7vh;
	overflow: hidden;
}

.marqueeSpace {
	width: 100vw;
}

.SNCF .max .dest {
	font-size: 9.2vh;
}

.SNCF .min .dest {
	font-size: 7.9vh;
}

.SNCF .alert {
	position: fixed;
	width: calc(100vw - 30px);
	bottom: 0;
	margin: 2vh 15px;
	font-size: 4vh;
	font-style: italic;
}


/* --- TIME --- */

.sncf-time {
	width: 25vh;
	height: 8.5vh;
	right: 15px;
	bottom: -4px;
	text-align: center;
	position: fixed;
	border-radius: 5px;
	box-shadow: rgb(255, 255, 255) 0px 1px 4px, rgb(255, 255, 255) 0px 0px 0px 3px;
	font-size: 6vh;
	font-weight: bold;
	z-index: 2;
}

.sncf-time .hour {
	top: 0.8vh;
	position: relative;
}

.sncf-time .minute {
	color: yellow;
	font-size: 3.7vh;
}

.departure .sncf-time {
	background-color: #003A79;
}

.arrival .sncf-time {
	background-color: #1F5628;
}