.IENA {
	width: 100vw;
	height: 100vh;
	overflow-x: hidden;
	font-family: "Achemine", Arial, sans-serif;
	color: #fff;
	margin: 0;
	overflow: hidden;
	white-space: nowrap;
	background-color: #CCCCCC;
	box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

@font-face {
	font-family: 'Achemine';
	src: url('../fonts/Achemine.ttf') format('truetype');
}

@font-face {
	font-family: 'Transilien';
	src: url('../fonts/TransilienSmall.ttf') format('truetype');
}

.IENA .head {
	background-color: #fff;
	height: 9vh;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	display: flex;
	align-items: center;
}

.IENA .head .title {
	color: #667C8A;
	left: 11.9vw;
	position: relative;
	font-weight: bold;
	font-size: 6.1vh;
}

.IENA .head .track {
	background-color: #181B3E;
	position: fixed;
	right: 1.3vw;
	top: 3.2vh;
	width: 5.5vw;
	border-radius: 9px;
	text-align: center;
	padding: 0.2vw;
	font-size: 2.5vh;
	font-weight: bold;
}

.IENA .IENA-time {
	background-color: #667C8A;
	width: 17vh;
	position: relative;
	height: 5.5vh;
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	z-index: 2;
	text-align: center;
	left: 2.5vw;
}

.IENA .hour {
	position: relative;
	top: 0.4vh;
	font-size: 4vh;
	font-weight: bold;
}

/* --- */

.IENA .départ {
	display: flex;
	margin: 0.7vw 1.1vw 0 1.1vw;

}

.IENA .départmax {
	background-color: #181B3E;
	border-radius: 2vh 0 2vh 2vh;
	height: 10.5vh;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	position: relative;
	z-index: 3;
	width: 100vw;
}

.IENA .départmin {
	background-color: #181B3E;
	border-radius: 2vh 0 0 2vh;
	height: 10vh;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	position: relative;
	z-index: 3;
	width: 100vw;
}

.IENA img {
	max-height: 5.5vh;
	margin-left: 1.2vw;
	max-width: 6vw;
}

.IENA .miss {
	font-family: "Transilien", Arial, sans-serif;
	padding-left: 1.2vw;
	font-size: 7vh;
	width: 10vw;
}

.IENA .dest {
	font-size: 6vh;
	padding-left: 1.9vh;
	font-weight: bold;
	overflow: hidden;
	max-width: 10vw;
}



.IENA .img,
.IENA .void {
	width: 7.4vw;
}

.IENA .trafic {
	width: 12vw;
}

.IENA .time {
	width: 13.8vw;
}

.IENA .track {
	width: 7.5vw;
}

.IENA .time {
	font-size: 4.6vh;
	width: 8vw;
	text-align: right;
	padding-right: 1vw;
}

.IENA .direct {
	font-style: italic;
	font-size: 4.5vh;
	font-weight: lighter;
	font-family: Corbel;
	color: #ffffffd1;
}

/* --- */
.IENA .départ .track {
	background-color: #181b3e;
	border-radius: 0 2vh 2vh 0;

	box-sizing: border-box;
	max-height: 10vh;
}

.tracki {
	max-height: 13vh !important;
}


.IENA .num {
	background-color: #fff;
	border-radius: 0 1vh 1vh 0;
	margin: 1.1vh 1.1vh 1.1vh 0;
	height: calc(100% - 2.2vh);
	color: #181b3e;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 6vh;
	font-weight: bold;
}

/* --- */



.IENA .stops-container {
	margin-left: 0.8vw;
	font-size: 4.5vh;
	overflow: hidden;
	height: 6vh;
	max-width: 80vw;
	border-radius: 2vh;
	background-color: #131637;
	margin-bottom: 1.5vh;
}

.IENA .stops {
	position: relative;
	white-space: normal;
	transition: 1s;
}

.IENA .stops.init {
	transition: top 0s ease 0.5s;
	animation: init 1s;
}

.IENA .stop {
	display: inline-block;
	white-space: nowrap;
}

.IENA .dot {
	position: relative;
	padding-left: 10px;
}

.dot-inner {
	display: inline-block;
	position: relative;
	background-color: #fff;
	border-radius: 5px;
	transform: rotate(45deg);
	width: 2vh;
	height: 0.5vh;
	top: -1.5vh;
}

.dot-outer {
	display: inline-block;
	position: relative;
	background-color: #fff;
	border-radius: 5px;
	transform: rotate(-45deg);
	width: 2vh;
	height: 0.5vh;
	left: -2vh;
	top: -3px;
}

@keyframes init {
	0% {
		margin-top: 0;
		opacity: 1;
	}

	48% {
		opacity: 1;
	}
	49% {
		margin-top: -6vh;
		opacity: 0;
	}

	51% {
		margin-top: 6vh;
		opacity: 0;
	}
	52% {
		opacity: 1;
	}

	100% {
		margin-top: 0;
		opacity: 1;
	}
}